<template>
  <v-container
    fluid
    class="pa-0"
    :style="marginTop"
  >
    <top-foreword entitle="AGENDA" cntitle="议程" />
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block :title="main_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="main_leader" />
      <agenda-block :items="mains" />
      <title-block :title="lunch_title.title" />
      <agenda-block :items="lunchs" />
      <title-block :title="first_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="first_leader" />
      <agenda-block :items="firsts" />
      <title-block :title="second_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="second_leader" />
      <agenda-block :items="seconds" />
      <title-block :title="third_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="third_leader" />
      <agenda-block :items="thirds" />
      <title-block :title="fourth_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="fourth_leader" />
      <agenda-block :items="fourth" />
      <title-block :title="fifth_title" />
      <agenda-leader class="mt-2 mt-sm-3" :item="fifth_leader" />
      <agenda-block :items="fifth" />
      <title-block :title="ended_title.title" />
      <agenda-block :items="endeds" />
    </div>    
    <bottom-banner />
  </v-container>
</template>
<script>
import TopForeword from '@/views/components/TopForeword.vue'
import TitleBlock from '@/views/components/TitleBlock'
import AgendaLeader from '@/views/components/AgendaLeader'
import AgendaBlock from '@/views/components/AgendaBlock'
import BottomBanner from '@/views/components/BottomBanner'
import Tools from '@/mixins/Tools.js'
import Datas from '@/mixins/Datas.js'
export default {
  name: 'Agenda',
  mixins: [Tools, Datas],
  components: {
    TopForeword,
    TitleBlock,
    AgendaLeader,
    AgendaBlock,
    BottomBanner
  }
}
</script>
