<template>
    <v-row
      no-gutters
      class="pa-0 ma-0"
    >
      <v-col cols="12">
        <v-img
          width="100%"
          :src="is_mobile ? '/images/top-foreward-m.jpg?2': '/images/top-foreward.jpg?2'"
          class="overflow-y-hidden"
        >
          <div class="mx-auto d-flex white--text fill-height flex-column justify-center align-start pl-3 pl-sm-0" :style="maxWidth">
            <div class="text-h3 text-sm-h1 mb-2 cs-line-height">{{ entitle}}</div>
            <div class="text-h4 text-sm-h3 cs-line-height"> {{ cntitle }}</div>
          </div>
        </v-img>
      </v-col>
    </v-row>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'TopForeword',
  mixins: [Tools],
  props: {
    entitle: {
      type: String,
      default: ''
    },
    cntitle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-line-height
  line-height: 1
</style>